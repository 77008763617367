#hdr .hdrMiddle{
    background-color: $color_primary;
    color: #fff;
}
#hdr.hdr--typeD .hdrMiddle .gNav > ul > li > a, #hdr.hdr--typeD .hdrMiddle .iconNav > ul > li > a{
    color:#fff;
}
.gNav li.hasChildren > a::after, #hdr.hdr--typeD .hdrMiddle .iconNav li a::after{
    border-color: #fff;
}
.searchForm i.material-icons{
    color: $color_primary;
}
.searchForm.searchFormA .searchForm__inner{
    border-radius: 20px;
    border: none;
}
.iconNav a:hover i{
    color: #fff;
}
.hdr--typeD .hdrBottom{
    background-color: $color_primary;
}
.drawer__accountText{
    background-color: #fff;
}
.drawer__nav .itemImg{
    border-radius: 0;
}
.drawer__nav li.hasChildren--click > ul > li{
    background: #F2F2F2;
}
@media (max-width: 767px){
    #hdr .hdrMiddle{
        background-color: #fff;
    }
    #hdr.hdr--typeD .hdrMiddle .gNav > ul > li > a, #hdr.hdr--typeD .hdrMiddle .iconNav > ul > li > a{
        color: $color_text;
    }
    .hdrBottom .hdrInner{
        padding: 0 10%;
    }
    .gNav.gNav--typeB > ul > li > a > span:last-child{
        margin:0;
    }
    .gNav.gNav--typeB > ul > li > a{
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        color: #fff;
    }
    .gNav.gNav--typeB > ul > li.hasChildren > a > span:last-child:after{
        border-color: #fff;
    }
}

.mainVisual{
    height: 1080px;
    max-height: 90vh;
    margin-bottom: 0;
    .mainVisual__catch{
        text-align: center;
        position: absolute;
        z-index: 3;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        picture{
            position: relative;
            margin-bottom: 80px;
            display: block;
            &::after{
                content: '';
                background: #fff;
                width: 60px;
                height: 2px;
                position: absolute;
                bottom: -40px;
                left: calc(50% - 30px);
            }
        }
        h2{
            font-size: 20px;
            font-weight: 500;
            color:#fff;
        }
    }
    #scrollBtn{
        color:#fff;
        &::before{
            border-color: #fff;
        }
    }
    @media (max-width: 767px){
        height: 600px;
        .mainVisual__catch h2{
            font-size: 12px;
            line-height: 12px;
        }
    }
}
.sec-concept{
    padding-bottom: 70px;
    margin-bottom: 30px;
    background: url('../img/sec2_bg.png') no-repeat right bottom;
}
@media (max-width: 767px){
    .sec-concept{
        background: url('../img/sec2_bg_sp.png') no-repeat right bottom;
        background-size: 90%;
    }
}
.conceptD2{
    padding: 100px 0 0 0;
    .conceptD__img{
        flex: 0 0 calc(57% + 20px);
        max-width: calc(57% + 20px);
        img{
            height: auto;
        }
    }
    .conceptD__text{
        border-radius: 50px;
        z-index: 1;
        margin: 13px 0 13px -20px;
        padding: 50px 90px;
        flex: 0 0 43%;
        max-width: 43%;
        h2{
            margin-bottom: 30px;
        }
        p{
            margin-bottom: 40px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    @media (max-width: 767px){
        padding: 50px 0 0 0;
        .container{
            padding:  0 15px;
        }
        .conceptD__img{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .conceptD__text{
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0;
            background-color: #fff;
            color: $color_text;
            padding: 20px;
            text-align: center;
            h2{
                text-align: center!important;
                margin-bottom: 10px;
            }
        }
    }
}
.itemImg{
    border-radius: 30px;
}
.sec-about01{
    .container{
        overflow: visible;
        .conceptD__row{
            overflow: visible;
        }
    }
    .conceptD__img{
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        img{
            object-fit: contain;
        }
    }
    .conceptD__text{
        color:#fff;
        margin:180px 0 0px calc(-50% - 60px);
        background-color: $color_primary;
        border-radius: 200px 0 0 200px;
        max-width: 100%;
        padding-left: 100px;
        flex: 0 0 100%;
        p{
            width: 50%;
        }
        @media (max-width: 767px){
            width: calc(100% + 15px);
            max-width: calc(100% + 15px);
            flex: 0 0 calc(100% + 15px);
            margin: -50px 0 20px 0;
            padding-left: 50px;
            .h2C{
                margin-bottom: 10px;
            }
            p{
                width: 100%;
                font-size: 14px;
            }
        }
    }
}
.sec-aboutBnr{
    .row > div{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .aboutBnr__title{
        font-weight: 500;
        margin-bottom: 10px;
        @media (max-width: 767px){
            font-size: 14px;
        }
    }
}
.sec-about02{
    .container{
        overflow: visible;
        .conceptD__row{
            overflow: visible;
            flex-direction: row-reverse;
        }
    }
    .conceptD__img{
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        img{
            object-fit: contain;
        }
    }
    .conceptD__text{
        color:#fff;
        margin:180px calc(-50% + -60px) 0px 0px;
        background-color: $color_primary;
        border-radius: 0 200px 200px 0;
        max-width: 100%;
        padding-right: 100px;
        flex: 0 0 100%;
        h2{
            width: 50%;
            margin: 0 0 20px auto;
            line-height: 36px;
        }
        p{
            width: 50%;
            margin: 0 0 0 auto;
        }
        @media (max-width: 767px){
            width: calc(100% + 15px);
            max-width: calc(100% + 15px);
            flex: 0 0 calc(100% + 15px);
            margin: -50px 0 20px 0;
            padding-right: 50px;
            .h2C{
                width: 100%;
                margin-bottom: 10px;
            }
            p{
                width: 100%;
                font-size: 14px;
            }
        }
    }
}
.h2-button{
    font-family: $base_font_family;
}
.h2G:after{
    background-color: $color_primary;
}
.conceptB{
    img{
        border-radius: 50px;
    }
    .h2C{
        text-align: left;
    }
    @media (max-width: 767px){
        .h2C{
            text-align: center;
        }
        .button__area{
            text-align: center;
        }
    }
}
.sec-voice{
    li{
        .flex{
            padding: 20px;
            background: #FFFBEB;
            border-radius: 10px;
            display: flex;
            align-items: center;
            gap:15px;
            .voice__img{
                width: 140px;
            }
            .voice__text{
                width: calc(100% - 140px);
                font-size: 12px;
                p{
                    font-size: 12px;
                }
                .voice__content{
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid $color_text;
                }
            }
            @media (max-width: 767px){
                flex-direction: column;
                .voice__text{
                    width: 100%;
                    .voice__name{
                        text-align: right;
                    }
                }
            }
        }
    }
}
.sec-news{
    a{
        border-radius: 30px;
    }
    .articleList__img{
        border-radius: 30px 30px 0 0;
    }
    .articleList__text{
        border-radius: 0 0 30px 30px;
    }
}
@media (max-width: 767px){
    .sec-gallery .container{
        padding: 0;
    }
}
#ftr h3{
    color: $color_primary;
    border-color: $color_primary;
}
#ftr .fNav__logo{
    margin-right: 20px;
}
@media (max-width: 767px){
    #ftr .row > nav:nth-of-type(1){
        order: 2;
    }
    #ftr .row > nav:nth-of-type(2){
        order: 3;
    }
    #ftr .row > nav:nth-of-type(3){
        order: 4;
    }
}
.page-company img{
    border-radius: 50px;
}
.page-company .h2C{
    font-size: 20px;
    span{
        border-bottom: 2px solid $color_primary;
    }
}